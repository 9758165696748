<template>
    <div class="">
        <div class="d-flex justify-content-start">
            <h4>Danh sách đơn vị</h4>
        </div>
        <div class="d-flex ">
            <div class="col-md-6 p-0 mt-2">
                <div class="col-md-4 p-0">
                    <input type="text" v-model="keyword" class="form-control" placeholder="Tên đơn vị" @input="search">
                </div>
            </div>
            <div class="d-flex justify-content-end col-md-6 pb-4">
                <b-button class="btn btn-success" @click="showCreate">Thêm mới</b-button>
            </div>
        </div>
        <div class="col-12 p-2 card card-custom">
            <div class="d-flex text-white bg-violet p-4 mb-2 rounded">
                <div class="d-flex flex-fill">Tên đơn vị </div>
                <div class="d-flex justify-content-end" style="width: 200px">Loại đơn vị</div>
                <div class=" d-flex justify-content-end p-0" style="width: 200px">Hành động</div>
            </div>
            <div>
                <div>
                    <ItemView  v-if="dataRoleDepartment" v-for="(item, index) in dataRoleDepartment" :key="index" :item="item"></ItemView>
                </div>
            </div>
        </div>

        <el-dialog :title="title" :visible.sync="outerVisible">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form class="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="col-md-12 d-flex">
                        <div class="col-md-4">
                            <ValidationProvider vid="name" name="Tên đơn vị" v-slot="{ errors, classes }"
                            >
                                <label for="">Tên đơn vị <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="form.name" placeholder="Tên đơn vị" tri>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0]
                                        }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-4">
                            <ValidationProvider vid="type" name="Loại đơn vị" v-slot="{ errors, classes }">
                                <label for="">Loại đơn vị <span class="text-danger">*</span></label>
                                <el-select v-model="form.type" @input="getAllDepartment" class="w-100">
                                    <el-option v-for="(item, index) in typeRole"
                                               :key="index"
                                               :label="item.name"
                                               :value="item.value">

                                    </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0]
                                        }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-md-4">
                            <ValidationProvider vid="parent_id" name="Đơn vị cha" v-slot="{ errors, classes }">
                                <label for="">Đơn vị cha</label>
                                <el-select v-model="form.parent_id" clearable class="w-100">
                                    <el-option v-for="(item, index) in allRoleDepartment"
                                               :key="index"
                                               :label="item.name" :value="item.id">

                                    </el-option>
                                </el-select>
                                <div class="fv-plugins-message-container">
                                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                            errors[0]
                                        }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div slot="footer" class="dialog-footer d-flex justify-content-center mt-4">
                        <button type="submit" v-if="is_update" class="el-button el-button--default bg-primary text-white">Sửa</button>
                        <button :disabled="is_disable" type="submit" v-else @click="innerVisible = true" class="el-button el-button--default bg-primary text-white">Thêm mới</button>
                    </div>
                </form>
            </ValidationObserver>

        </el-dialog>
    </div>
</template>

<script>
import {
    GET_LIST_ROLE_DEPARTMENT,
    UPDATE_ROLE_DEPARTMENT,
    STORE_ROLE_DEPARTMENT,
    TYPE_ROLE_DEPARTMENT,
    ACTION_ROLE_DEPARTMENT,
    BLOCK,
    DEPARTMENT,
    PART, ALL_ROLE_DEPARTMENT
} from "../../../core/services/store/department/department.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ItemView from "./components/ItemView";
import {mapGetters} from "vuex";

export default {
    name: "RoleDepartment",
    components: {
        ItemView
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    data() {
        return {
            accountType: [],
            form: {
                id: '',
                name: '',
                type: '',
                parent_id: ''
            },
            filterText: '',
            data: [],
            outerVisible: false,
            is_update: false,
            title: '',
            allRoleDepartment: [],
            typeRole: TYPE_ROLE_DEPARTMENT,
            confirm: false,
            titleConfirm: '',
            hide: false,
            dataConfirm: {},
            keyword: '',
            block: BLOCK,
            department: DEPARTMENT,
            part: PART,
            is_disable: false
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý đơn vị", route: '#'},
            {title: "Danh sách đơn vị"}
        ]);
        this.getData();
    },
    methods: {
        getData() {
            this.$store.dispatch(GET_LIST_ROLE_DEPARTMENT, {keyword: this.keyword});
        },
        remove(data) {
            this.dataConfirm = data;
            this.confirm = true;
            this.hide = true;
            this.titleConfirm = 'Ẩn bộ phận ' + data.name;
        },
        active(data) {
            this.dataConfirm = data;
            this.confirm = true;
            this.hide = false;
            this.titleConfirm = 'Kích hoạt Bộ phận ' + data.name;
        },
        action() {
            this.$store.dispatch(ACTION_ROLE_DEPARTMENT, {
                id: this.dataConfirm.id,
                status: this.hide ? 1 : 0
            }).then(res => {
                this.getData();
                this.confirm = false;
                if (this.hide) {
                    this.$bvToast.toast('Ẩn thành công', {
                        title: 'Cập nhật',
                        variant: 'success',
                        solid: true
                    });
                } else {
                    this.$bvToast.toast('Kích hoạt thành công', {
                        title: 'Cập nhật',
                        variant: 'success',
                        solid: true
                    });
                    this.getData();
                }
            })
        },
        append(node, data) {
            console.log(node, data)
        },
        update(data) {
            this.$store.dispatch(UPDATE_ROLE_DEPARTMENT, {}).then(res => {
                if (res.data) {
                    // this.dataRoleDepartment = res.data;
                }
            })
        },
        showUpdate(data) {
            this.form = data;
            this.outerVisible = true;
            this.is_update = true;
            this.title = 'Cập nhật đơn vị';
            this.getAllDepartment();
        },
        submit() {

        },
        onSubmit() {
            this.form.name.trim();
            if (this.is_update) {
                this.$store.dispatch(UPDATE_ROLE_DEPARTMENT, this.form).then(res => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Cập nhật',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$bvToast.toast(res.message, {
                            title: 'Cập nhật',
                            variant: 'success',
                            solid: true
                        });
                        this.outerVisible = false;
                        this.getData();
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                })
            } else {
                this.is_disable = true;
                this.$store.dispatch(STORE_ROLE_DEPARTMENT, this.form).then(res => {
                    if (res.error) {
                        this.$bvToast.toast(res.message, {
                            title: 'Cập nhật',
                            variant: 'danger',
                            solid: true
                        });
                    } else {
                        this.$bvToast.toast(res.message, {
                            title: 'Cập nhật',
                            variant: 'success',
                            solid: true
                        });
                        this.outerVisible = false;
                        this.getData();
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                }).finally(() => {
                    this.is_disable = false;
                });
            }
        },
        showCreate() {
            this.title = 'Thêm mới đơn vị';
            this.is_update = false;
            this.outerVisible = true;
            this.form = {};
        },
        changeType(type) {
            let text = '';
            switch (type) {
                case BLOCK:
                    text = 'Khối';
                    break;
                case DEPARTMENT:
                    text = 'Phòng ban';
                    break;
                case PART:
                    text = 'Bộ phận';
                    break;
            }
            return text;
        },
        search() {
            setTimeout(this.getData, 1000);
        },
        getAllDepartment() {
            this.$store.dispatch(ALL_ROLE_DEPARTMENT, {type: this.form.type}).then(res => {
                if (res.data) {
                    this.allRoleDepartment = res.data;
                }
            })
        },
    },
    computed: {
        ...mapGetters(["dataRoleDepartment"]),
    },
}
</script>

<style scoped>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: Helvetica;
}

#tree {
    width: 100%;
    height: 100%;
}

.text-red {
    color: red;
}

.text-blue {
    color: blue;
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}

.text-luc {
    color: #002D55;
}

.el-dialog__header {
    display: flex !important;
}

.bg-violet {
    background: #500DA7 !important;
}

.text-violet {
    color: #500DA7 !important;
}

.el-collapse-item__arrow el-icon-arrow-right {
    display: none;
}
</style>